<template>
  <div>
    <b-row class="profile-icons-svg">
      <b-col lg="4">
        <div class="iq-card ">
        <div class="iq-card-body pb-1">
          <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.events') }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
            <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
          </svg>
          </div>
          <p class="font-size-30 text-end m-0 pt-4">36</p>
        </div>
          </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.orders') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
                <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">36</p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.favourite') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
                <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">36</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="profile-icons-svg">
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.events') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
                <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">36</p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.comments') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
                <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">36</p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.shopping') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
                <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">36</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-for="(item,index) in charts" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <ApexChart :element="item.type" :chartOption="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ApexChart from '../components/hightCharts'
export default {
  name: 'user-home',
  components: { ApexChart },
  data () {
    return {
      charts: [
        {
          title: 'Line Chart',
          type: 'line',
          bodyData: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            series: [{
              name: 'Desktops',
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
            }],
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Product Trends by Month',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              }
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
            }
          }
        },
        {
          title: 'Column Chart',
          type: 'column',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                borderRadius: 4
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            colors: ['#0084ff', '#00ca00', '#ffc107'],
            series: [{
              name: 'Net Profit',
              data: [44, 55, 57, 56, 61, 58]
            }, {
              name: 'Revenue',
              data: [76, 85, 101, 98, 87, 105]
            }, {
              name: 'Free Cash Flow',
              data: [35, 41, 36, 26, 45, 48]
            }],
            xaxis: {
              categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1

            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return '$ ' + val + ' thousands'
                }
              }
            }
          }
        },
        {
          title: 'Mixes Chart',
          type: 'mixes',
          bodyData: {
            chart: {
              height: 350,
              type: 'line',
              stacked: false
            },
            stroke: {
              width: [0, 2, 5],
              curve: 'smooth'
            },
            plotOptions: {
              bar: {
                columnWidth: '50%'
              }
            },
            colors: ['#ffc107', '#00ca00', '#0084ff'],
            series: [{
              name: 'Facebook',
              type: 'column',
              data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
            }, {
              name: 'Vine',
              type: 'area',
              data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
            }, {
              name: 'Dribbble',
              type: 'line',
              data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
            }],
            fill: {
              opacity: [0.85, 0.25, 1],
              gradient: {
                inverseColors: false,
                shade: 'light',
                type: 'vertical',
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
              }
            },
            labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'],
            markers: {
              size: 0
            },
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              min: 0
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== 'undefined') {
                    return y.toFixed(0) + ' views'
                  }
                  return y
                }
              }
            },
            legend: {
              labels: {
                useSeriesColors: true
              },
              markers: {
                customHTML: [
                  function () {
                    return ''
                  }, function () {
                    return ''
                  }, function () {
                    return ''
                  }
                ]
              }
            }
          }
        },
        {
          title: 'Bubble Charts',
          type: 'bubble',
          bodyData: {
            chart: {
              height: 350,
              type: 'bubble'
            },
            dataLabels: {
              enabled: false
            },
            series: [{
              name: 'Product1',
              data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 40
              })
            },
            {
              name: 'Product2',
              data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 40
              })
            },
            {
              name: 'Product3',
              data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                min: 10,
                max: 40
              })
            }
            ],
            fill: {
              type: 'gradient'
            },
            colors: ['#0084ff', '#00ca00', '#e64141'],
            title: {
              text: '3D Bubble Chart'
            },
            xaxis: {
              tickAmount: 12,
              type: 'datetime',

              labels: {
                rotate: 0
              }
            },
            yaxis: {
              max: 40
            },
            theme: {
              palette: 'palette2'
            }
          }
        },
        {
          title: 'Pie Charts',
          type: 'pie',
          bodyData: {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            series: [44, 55, 13, 43, 22],
            colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400', '#00d0ff'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
        },
        {
          title: 'Line Area Chart',
          type: 'line-area',
          bodyData: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            colors: ['#0084ff', '#00ca00'],
            series: [{
              name: 'series1',
              data: [31, 40, 28, 51, 42, 109, 100]
            }, {
              name: 'series2',
              data: [11, 32, 45, 32, 34, 52, 41]
            }],

            xaxis: {
              type: 'datetime',
              categories: ['2018-09-19T00:00:00', '2018-09-19T01:30:00', '2018-09-19T02:30:00', '2018-09-19T03:30:00', '2018-09-19T04:30:00', '2018-09-19T05:30:00', '2018-09-19T06:30:00']
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        },
        {
          title: 'Bar Chart',
          type: 'bar',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            dataLabels: {
              enabled: false
            },
            series: [{
              data: [470, 540, 580, 690, 1100, 1200, 1380]
            }],
            xaxis: {
              categories: ['Netherlands', 'Italy', 'France', 'Japan', 'United States', 'China', 'Germany']
            }
          }
        },
        {
          title: 'Radial Bar Charts',
          type: 'radial',
          bodyData: {
            chart: {
              height: 350,
              type: 'radialBar'
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px'
                  },
                  value: {
                    fontSize: '16px'
                  },
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return 249
                    }
                  }
                }
              }
            },
            series: [44, 55, 67, 83],
            labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
            colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400']
          }
        }
      ]
    }
  },
  methods: {
    generateData (baseval, count, yrange) {
      var i = 0
      var series = []
      while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15

        series.push([baseval, y, z])
        baseval += 86400000
        i++
      }
      return series
    }
  },
  mounted () {
    core.index()
  }
}
</script>

<style>
.text-end{
  text-align: end;
}
.profile-icons-svg svg path {
  fill: var(--iq-secondary-dark) !important;
}
</style>
